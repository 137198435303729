import { defineStore } from 'pinia';

export type LoaderState = {
  status: boolean;
  text: string | null;
};

export const useLoaderStore = defineStore('loader', {
  state: (): LoaderState => ({
    status: false,
    text: null,
  }),
  actions: {
    loaderOn(text?: string) {
      this.status = true;
      this.text = text ?? null;
    },
    loaderOff() {
      this.$reset();
    },
  },
});
