import Surveys from '@/api/endpoints/Surveys';
import { Type } from '@/constants/Steps';
import { TierName, defaultEditors } from '@opinionx/tier';
import { notNull } from '@/helpers/notNull';
import { defineStore } from 'pinia';
import { useMessageStore } from './message';
import { useUserStore } from './user';
import axios from 'axios';
import { useWorkspaceStore } from './workspace';
import Workspaces from '@/api/endpoints/Workspaces';

type NotificationSetting = {
  enabled: boolean;
  memberIds: string[];
};

export type CategoricalEnrichmentField = {
  name: string;
  _id: string;
  type: EnrichmentFieldType.CATEGORICAL;
  categories: {
    _id: string;
    category: string;
  }[];
};

export enum EnrichmentFieldType {
  CATEGORICAL = 'categorical',
  UNIQUE = 'unique',
}

export type UniqueEnrichmentField = {
  type: EnrichmentFieldType.UNIQUE;
  name: string;
  _id: string;
};

export type EnrichmentField<Type = EnrichmentFieldType> = Extract<
  UniqueEnrichmentField | CategoricalEnrichmentField,
  { type: Type }
>;

export enum ImageFormat {
  BANNER = 'banner',
  IN_LINE = 'in-line',
}

export type StepImage = {
  url: string;
  format: ImageFormat;
};

export type Step = Record<string, any> & {
  type: Type;
  _id: string;
  image?: StepImage;
} & ({ question: string } | { heading: string });

export type SurveyState = {
  _id: string | null;
  publicId: string | null;
  shareableLinkId: string | null;
  title: string | null;
  language: string | null;
  closed: boolean;
  branding: boolean;
  intro: Record<string, any> | null;
  steps: Step[];
  finish: Record<string, any> | null;
  tier: TierName | null;
  extraEditorSeats: number;
  members: Record<string, any>[];
  emailInvite: {
    title: string;
    body: string;
    senderName: string;
    replyTo: string;
    singleUseLinks: boolean;
  };
  cluster: {
    nParticipants: number;
    nClusters: number;
    randomSeed: number | null;
    correlationThreshold: number;
    participantResponseCompletionThreshold: number;
    stepResponseCompletionThreshold: number;
    includeIncompleteParticipants: boolean;
    thresholdParticipants: number;
    updatedAt: string;
  } | null;
  saving: boolean;
  path: Record<string, any> | null;
  shareableLinkHideIdentify: boolean | null;
  shareableLinkSaveAccess: boolean | null;
  workspaceId: string | null;
  stats: {
    participants: number;
    opinions: number;
    votes: number;
  } | null;
  notificationSettings: { submission: NotificationSetting } | null;
  updatedAt: string | null;
  googleSheetsIntegration: {
    sheetId: string | null;
  } | null;
  enrichmentFields: null | EnrichmentField[];
};

export const useSurveyStore = defineStore('survey', {
  state: (): SurveyState => ({
    _id: null,
    publicId: null,
    shareableLinkId: null,
    title: null,
    language: null,
    closed: false,
    branding: false,
    intro: null,
    steps: [],
    finish: null,
    tier: null,
    extraEditorSeats: 0,
    members: [],
    emailInvite: {
      title: '',
      body: '',
      senderName: '',
      replyTo: '',
      singleUseLinks: false,
    },
    cluster: null,
    saving: false,
    path: null,
    shareableLinkHideIdentify: null,
    shareableLinkSaveAccess: null,
    workspaceId: null,
    stats: null,
    notificationSettings: null,
    updatedAt: null,
    googleSheetsIntegration: null,
    enrichmentFields: null,
  }),
  getters: {
    isWriteAccess(state) {
      const userStore = useUserStore();
      if (userStore?.isAdmin) {
        return true;
      }

      const userMember = state.members.find(
        (member) => userStore?._id === member?.user?._id,
      );
      if (!userMember) {
        return false;
      }
      return userMember.access == 'write';
    },
    isMember(state) {
      const userStore = useUserStore();
      if (!userStore.isAuthenticated) {
        return false;
      }
      if (userStore?.isAdmin) {
        return true;
      }
      const userMember = state.members.find(
        (member) => userStore?._id === member?.user?._id,
      );
      if (!userMember) {
        return false;
      }
      return true;
    },
    isSurveyWorkspaceMember(): boolean {
      const userStore = useUserStore();

      if (userStore.isAdmin) {
        return true;
      }
      const workspaceStore = useWorkspaceStore();
      return (
        workspaceStore.currentWorkspace?._id === this.workspaceId &&
        workspaceStore.currentWorkspace.members.some(
          (member) => member.user === userStore._id,
        )
      );
    },
    isProOrAbove(): boolean {
      return (
        this.tier === TierName.PRO || this.isScaleOrAbove || this.isEnterprise
      );
    },
    isScaleOrAbove(): boolean {
      return this.tier === TierName.SCALE || this.isEnterprise;
    },
    isEnterprise(): boolean {
      return this.tier === TierName.ENTERPRISE;
    },
    hasSurveyTierAccess() {
      return (tierName: TierName) => {
        switch (tierName) {
          case TierName.ENTERPRISE:
            return this.isEnterprise;
          case TierName.SCALE:
            return this.isScaleOrAbove;
          case TierName.PRO:
            return this.isProOrAbove;
          default:
            return true;
        }
      };
    },
    latestPath(): string {
      return this.path?.paths.slice(-1)[0];
    },
    editorSeats(state): number {
      return (
        state.extraEditorSeats + defaultEditors(this.tier ?? TierName.FREE)
      );
    },
    hasEditorSeats(): boolean {
      return (
        this.editorSeats -
          this.members.filter(({ access }) => access === 'write').length >
        0
      );
    },
    isSampleViewerMode(): boolean {
      return !!this.router.currentRoute.value.query.sample;
    },
    enrichmentFieldsCategories(): Map<string, string> {
      const enrichmentFieldMap = new Map<string, string>();
      this.enrichmentFields?.forEach((enrichmentField) => {
        if (enrichmentField.type === EnrichmentFieldType.CATEGORICAL) {
          enrichmentField.categories.forEach((category) =>
            enrichmentFieldMap.set(category._id, category.category),
          );
        }
      });
      return enrichmentFieldMap;
    },
  },
  actions: {
    async findOne(id?: string) {
      const surveyId = id ?? this._id;
      try {
        this.saving = true;
        notNull(surveyId, 'surveyId');
        const { data } = await Surveys.findOne(surveyId);
        this.$state = data;
        await this.findWorkspace();
      } catch (error) {
        await this.router.replace({ name: 'surveys' });
        throw error;
      } finally {
        this.saving = false;
      }
    },
    findWorkspace() {
      if (!this.workspaceId) {
        return;
      }
      return useWorkspaceStore().setCurrentWorkspaceById(this.workspaceId);
    },
    async findOneByShareableLinkId(shareableLinkId: string) {
      try {
        this.saving = true;
        const { data } =
          await Surveys.findOneByShareableLinkId(shareableLinkId);
        this.$state = data;
        if (this.isMember) {
          this.router.replace({ name: 'results', params: { id: this._id } });
        }
      } catch (error) {
        await this.router.replace({ name: 'surveys' });
        throw error;
      } finally {
        this.saving = false;
      }
    },
    async update(payload: Record<string, any>) {
      if (this.isSampleViewerMode) {
        return;
      }
      this.saving = true;
      try {
        notNull(this._id, '_id');
        const { data } = await Surveys.update(this._id, payload);
        this.$state = data;
      } finally {
        this.saving = false;
      }
    },
    async uploadImage(payload: any, page: 'intro' | 'finish') {
      if (this.isSampleViewerMode) {
        return;
      }
      this.saving = true;
      try {
        notNull(this._id, '_id');
        const formData = new FormData();
        formData.append('file', payload);
        const { data } = await Surveys.uploadImage(this._id, formData, {
          page,
        });
        this.$state = data;
      } finally {
        this.saving = false;
      }
    },
    async createStep(index: number, payload: Record<string, any>) {
      if (this.isSampleViewerMode) {
        return;
      }
      this.saving = true;
      try {
        notNull(this._id, '_id');
        const { data } = await Surveys.createStep(this._id, index, {
          step: payload,
        });
        this.$state = data;
      } finally {
        this.saving = false;
      }
    },
    async updateOrder(payload: Record<string, any>[]) {
      if (this.isSampleViewerMode) {
        return;
      }
      this.saving = true;
      try {
        notNull(this._id, '_id');
        const { data } = await Surveys.updateOrder(this._id, payload);
        this.$state = data;
      } finally {
        this.saving = false;
      }
    },
    async updateStep(
      stepId: string,
      payload: {
        type: Type;
      } & Record<string, any>,
    ) {
      if (this.isSampleViewerMode) {
        return;
      }
      this.saving = true;
      try {
        notNull(this._id, '_id');
        const { data } = await Surveys.updateStep(this._id, stepId, {
          step: payload,
        });
        this.$state = data;
      } finally {
        this.saving = false;
      }
    },
    async UpdateStepImage(stepId: string, file?: File) {
      if (this.isSampleViewerMode) {
        return;
      }
      notNull(file, 'file');
      this.saving = true;
      const payload = { file };
      try {
        notNull(this._id, '_id');
        const { data } = await Surveys.updateStepImage(
          this._id,
          stepId,
          payload,
        );
        this.$state = data;
      } finally {
        this.saving = false;
      }
    },
    async replaceStep(
      stepId: string,
      payload: {
        type: Type;
      } & Record<string, any>,
    ) {
      if (this.isSampleViewerMode) {
        return;
      }
      this.saving = true;
      try {
        notNull(this._id, '_id');
        const { data } = await Surveys.replaceStep(this._id, stepId, {
          step: payload,
        });
        this.$state = data;
      } finally {
        this.saving = false;
      }
    },
    async removeStep(stepId: string) {
      if (this.isSampleViewerMode) {
        return;
      }
      this.saving = true;
      try {
        notNull(this._id, '_id');
        const { data } = await Surveys.removeStep(this._id, stepId);
        this.$state = data;
      } finally {
        this.saving = false;
      }
    },
    async duplicateStep(stepId: string) {
      if (this.isSampleViewerMode) {
        return;
      }
      this.saving = true;
      try {
        notNull(this._id, '_id');
        const { data } = await Surveys.duplicateStep(this._id, stepId);
        this.$state = data;
      } finally {
        this.saving = false;
      }
    },
    async addMember(payload: Record<string, any>) {
      if (this.isSampleViewerMode) {
        return;
      }
      this.saving = true;
      try {
        notNull(this._id, '_id');
        const { data } = await Surveys.addMember(this._id, payload);
        const messageStore = useMessageStore();
        messageStore.message('success', 'Member invited.');
        this.$state = data;
      } finally {
        this.saving = false;
      }
    },
    async addMemberByShareableLink() {
      if (this.isSampleViewerMode) {
        return;
      }
      this.saving = true;
      try {
        notNull(this.shareableLinkId, 'shareableLinkId');
        const { data } = await Surveys.addMemberByShareableLink(
          this.shareableLinkId,
        );
        const messageStore = useMessageStore();
        messageStore.message('success', 'Added as member');
        this.$state = data;
      } finally {
        this.saving = false;
      }
    },

    async updateMember(memberId: string, payload: Record<string, any>) {
      if (this.isSampleViewerMode) {
        return;
      }
      this.saving = true;
      try {
        notNull(this._id, '_id');
        const { data } = await Surveys.updateMember(
          this._id,
          memberId,
          payload,
        );
        const messageStore = useMessageStore();
        messageStore.message('success', 'Member updated.');
        this.$state = data;
      } finally {
        this.saving = false;
      }
    },
    async removeMember(memberId: string) {
      if (this.isSampleViewerMode) {
        return;
      }
      this.saving = true;
      try {
        notNull(this._id, '_id');
        const { data } = await Surveys.removeMember(this._id, memberId);
        const messageStore = useMessageStore();
        messageStore.message('success', 'Team member removed.');
        this.$state = data;
      } finally {
        this.saving = false;
      }
    },
    async updatePath(path: string) {
      if (this.isSampleViewerMode) {
        return;
      }
      this.saving = true;
      try {
        notNull(this._id, '_id');
        const { data } = await Surveys.updatePath(this._id, { path });
        const messageStore = useMessageStore();
        messageStore.message('success', 'Custom url updated');
        this.$state = data;
      } catch (error) {
        const messageStore = useMessageStore();
        if (axios.isAxiosError(error)) {
          switch (error.response?.status) {
            case 409:
              messageStore.message('error', 'This custom url is already taken');
              return;
            case 400:
              messageStore.message('error', 'Custom url is not valid');
              return;
          }
        }
        throw error;
      } finally {
        this.saving = false;
      }
    },
    async addMemberToWorkspace(email: string) {
      if (this.isSampleViewerMode) {
        return;
      }
      const messageStore = useMessageStore();
      this.saving = true;
      try {
        notNull(this.workspaceId, 'workspaceId');
        await Workspaces.addMember(this.workspaceId, {
          email,
          access: 'teammate',
        });
        await useWorkspaceStore().find();
        notNull(this._id, '_id');
        await this.findOne(this._id);
        messageStore.message('success', 'Member added.');
      } catch {
        useMessageStore().message(
          'error',
          'Error adding Survey member to workspace',
        );
      } finally {
        this.saving = false;
      }
    },
    async addImagesToMultipleChoiceStepAnswer(
      stepId: string,
      answerId: String,
      images: File[],
    ) {
      const payload = {
        files: images,
        operations: images.map((_, index) =>
          index === 0
            ? { operation: 'update', answerId }
            : { operation: 'create' },
        ),
      };
      this.saving = true;
      try {
        notNull(this._id, '_id');

        const { data } = await Surveys.addImagesToMultipleChoiceAnswers(
          this._id,
          stepId,
          payload,
        );
        this.$state = data;
      } finally {
        this.saving = false;
      }
    },
    async addMultipleChoiceStepAnswers(stepId: string, payload: any) {
      if (this.isSampleViewerMode) {
        return;
      }
      this.saving = true;
      try {
        notNull(this._id, '_id');

        const { data } = await Surveys.addMultipleChoiceStepAnswers(
          this._id,
          stepId,
          payload,
        );
        this.$state = data;
      } finally {
        this.saving = false;
      }
    },
    async updateMultipleChoiceStepAnswer(
      stepId: string,
      answerId: string,
      payload: any,
    ) {
      if (this.isSampleViewerMode) {
        return;
      }
      this.saving = true;
      try {
        notNull(this._id, '_id');

        const { data } = await Surveys.updateMultipleChoiceStepAnswer(
          this._id,
          stepId,
          answerId,
          payload,
        );
        this.$state = data;
      } finally {
        this.saving = false;
      }
    },
    async removeMultipleChoiceStepAnswer(stepId: string, answerId: string) {
      if (this.isSampleViewerMode) {
        return;
      }
      this.saving = true;
      try {
        notNull(this._id, '_id');

        const { data } = await Surveys.removeMultipleChoiceStepAnswer(
          this._id,
          stepId,
          answerId,
        );
        this.$state = data;
      } finally {
        this.saving = false;
      }
    },
    async updateNotificationSettings(payload: any) {
      if (this.isSampleViewerMode) {
        return;
      }
      this.saving = true;
      try {
        notNull(this._id, '_id');

        const { data } = await Surveys.updateNotificationSettings(
          this._id,
          payload,
        );
        this.$state = data;
      } finally {
        this.saving = false;
      }
    },
  },
});
