import ApiService, { Params } from '../ApiService';

export default {
  googleAuth(payload: any, params?: Params) {
    const config = {
      skipAuthRefresh: true,
      params,
    };
    return ApiService.post(`/auth/google`, payload, config);
  },
  signUp(payload: any, params?: Params) {
    const config = {
      skipAuthRefresh: true,
      params,
    };
    return ApiService.post(`/auth/sign-up`, payload, config);
  },
  signIn(payload: any, params?: Params) {
    const config = {
      skipAuthRefresh: true,
      params,
    };
    return ApiService.post(`/auth/sign-in`, payload, config);
  },
  refreshToken() {
    return ApiService.post(`/auth/refresh`);
  },
  forgotPassword(payload: any) {
    const config = {
      skipAuthRefresh: true,
    };
    return ApiService.post(`/auth/forgot-password`, payload, config);
  },
  resetPasswordToken(payload: any, params: Params) {
    const config = {
      params,
      skipAuthRefresh: true,
    };
    return ApiService.post(`/auth/reset-password`, payload, config);
  },

  updatePassword(payload: any) {
    return ApiService.post(`/auth/update-password`, payload);
  },
  sendVerificationEmail() {
    return ApiService.post(`/auth/send-email-verification`);
  },
  verifyEmail(params: any) {
    const config = {
      params,
    };
    return ApiService.post(`/auth/verify-email`, {}, config);
  },
};
