export default [
  {
    path: 'sign-in',
    name: 'workspace-invite-sign-in',
    component: () => import('@/views/WorkspaceInvite/SignIn.vue'),
    meta: {
      name: 'Sign In',
      keepQueryParams: true,
    },
  },
  {
    path: '',
    name: 'workspace-invite-sign-up',
    component: () => import('@/views/WorkspaceInvite/SignUp.vue'),
    meta: {
      name: 'Sign Up',
      keepQueryParams: true,
    },
  },
];
