export default [
  {
    path: 'overview',
    name: 'admin-overview',
    component: () => import('@/views/Admin/Overview/OverviewPage.vue'),
    meta: {
      name: 'Admin Overview',
      authorize: true,
      admin: true,
    },
  },
  {
    path: 'surveys',
    name: 'admin-surveys',
    component: () => import('@/views/Admin/Surveys/SurveysPage.vue'),
    meta: {
      name: 'Admin Surveys',
      authorize: true,
      admin: true,
    },
  },
  {
    path: 'users',
    name: 'admin-users',
    component: () => import('@/views/Admin/Users/UsersPage.vue'),
    meta: {
      name: 'Admin Users',
      authorize: true,
      admin: true,
    },
  },
  {
    path: 'workspaces',
    name: 'admin-workspaces',
    component: () => import('@/views/Admin/Workspaces/WorkspacesPage.vue'),
    meta: {
      name: 'Admin Workspaces',
      authorize: true,
      admin: true,
    },
  },
];
