export default [
  {
    path: 'intro',
    name: 'preview-intro',
    component: () => import('@/views/Survey/IntroPage.vue'),
    meta: {
      name: 'Survey Preview Intro',
      authorize: true,
      user: true,
      preview: true,
    },
  },
  {
    path: 'survey',
    name: 'preview-survey',
    component: () => import('@/views/Survey/SurveyHome.vue'),
    meta: {
      name: 'Survey Preview',
      authorize: true,
      user: true,
      preview: true,
      showFooter: true,
      scrollToTop: true,
    },
  },
  {
    path: 'finish',
    name: 'preview-finish',
    component: () => import('@/views/Survey/FinishPage.vue'),
    meta: {
      name: 'Survey Preview Finish',
      authorize: true,
      user: true,
      preview: true,
      scrollToTop: true,
    },
  },
  {
    path: 'closed',
    name: 'preview-closed',
    component: () => import('@/views/Survey/Closed/ClosedPage.vue'),
    meta: {
      name: 'Survey Preview Closed',
      authorize: true,
      user: true,
      preview: true,
    },
  },
];
