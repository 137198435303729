<template>
  <v-dialog v-model="loaderStore.status" :scrim="false" persistent width="300">
    <v-card color="primary" :title="loaderStore.text || 'Please stand by'">
      <v-card-text>
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { useLoaderStore } from '@/stores/loader';
import { mapStores } from 'pinia';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LoaderOverlay',
  computed: {
    ...mapStores(useLoaderStore),
  },
});
</script>
