// src/constants.ts
var FREE_SURVEY_EDITOR_CAP = 2;
var PRO_SURVEY_EDITOR_CAP = 2;
var SCALE_SURVEY_EDITOR_CAP = 3;
var ENTERPRISE_SURVEY_EDITOR_CAP = 5;

// src/tier.entity.ts
var TierName = /* @__PURE__ */ ((TierName2) => {
  TierName2["FREE"] = "free";
  TierName2["PRO"] = "pro";
  TierName2["SCALE"] = "scale";
  TierName2["ENTERPRISE"] = "enterprise";
  return TierName2;
})(TierName || {});
var TierType = /* @__PURE__ */ ((TierType2) => {
  TierType2["NONE"] = "none";
  TierType2["MONTHLY"] = "monthly";
  TierType2["ANNUALLY"] = "annually";
  TierType2["MANUALLY"] = "manually";
  TierType2["APPSUMO"] = "appsumo";
  return TierType2;
})(TierType || {});
var TierDisplayNames = {
  ["free" /* FREE */]: "Free",
  ["pro" /* PRO */]: "Ask",
  ["scale" /* SCALE */]: "Analyze",
  ["enterprise" /* ENTERPRISE */]: "Accelerate"
};
var Tier = class {
  name;
  type;
  renewsAt;
  cancelAtPeriodEnd;
};
var isTierName = (tierName) => {
  if (typeof tierName !== "string") {
    return false;
  }
  return Object.values(TierName).includes(tierName);
};
var isTierType = (tierType) => {
  if (typeof tierType !== "string") {
    return false;
  }
  return Object.values(TierType).includes(tierType);
};

// src/helpers.ts
var defaultEditors = (tier) => {
  switch (tier) {
    case "free" /* FREE */:
      return FREE_SURVEY_EDITOR_CAP;
    case "pro" /* PRO */:
      return PRO_SURVEY_EDITOR_CAP;
    case "scale" /* SCALE */:
      return SCALE_SURVEY_EDITOR_CAP;
    case "enterprise" /* ENTERPRISE */:
      return ENTERPRISE_SURVEY_EDITOR_CAP;
  }
};
export {
  ENTERPRISE_SURVEY_EDITOR_CAP,
  FREE_SURVEY_EDITOR_CAP,
  PRO_SURVEY_EDITOR_CAP,
  SCALE_SURVEY_EDITOR_CAP,
  Tier,
  TierDisplayNames,
  TierName,
  TierType,
  defaultEditors,
  isTierName,
  isTierType
};
