import { RouteRecordRaw } from 'vue-router';
import SurveyDashboardResultsRoute from './Results/ResultsRoute';
import SetupRoute from './Setup/SetupRoute';

const SurveyDashboardRoutes: RouteRecordRaw[] = [
  SetupRoute,
  SurveyDashboardResultsRoute,
  {
    path: 'settings',
    name: 'settings',
    component: () =>
      import('@/views/SurveyDashboard/SettingsTab/SettingsPage.vue'),
    meta: {
      name: 'Survey Settings',
      authorize: true,
      user: true,
    },
  },
];

export default SurveyDashboardRoutes;
