export default [
  {
    path: 'surveys',
    name: 'surveys',
    component: () => import('@/views/UserDashboard/SurveysPage.vue'),
    meta: {
      name: 'Surveys',
      authorize: true,
      user: true,
    },
  },
  {
    path: 'shared',
    name: 'shared-with-me',
    component: () => import('@/views/UserDashboard/SharedWithMePage.vue'),
    meta: {
      name: 'Surveys',
      authorize: true,
      user: true,
    },
  },
  {
    path: 'samples',
    name: 'samples',
    component: () => import('@/views/UserDashboard/SampleSurveysPage.vue'),
    meta: {
      name: 'Sample Surveys',
      authorize: true,
      user: true,
    },
  },
  {
    path: 'account',
    name: 'account',
    component: () => import('@/views/UserDashboard/AccountPage.vue'),
    meta: {
      name: 'Account',
      authorize: true,
      user: true,
    },
  },
  {
    path: 'upgrade',
    name: 'upgrade',
    component: () => import('@/views/UserDashboard/UpgradePage.vue'),
    meta: {
      name: 'Upgrade',
      authorize: true,
      user: true,
    },
  },
];
