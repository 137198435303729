import { RouteRecordRaw } from 'vue-router';

const SetupRoute: RouteRecordRaw = {
  path: 'setup',
  name: 'setup',
  redirect: { name: 'setup-survey' },
  component: () => import('@/views/SurveyDashboard/SetupTab/SetupPage.vue'),
  meta: {
    name: 'Survey Setup',
    authorize: true,
    user: true,
  },
  children: [
    {
      name: 'setup-survey',
      path: 'survey',
      component: () =>
        import('@/views/SurveyDashboard/SetupTab/Survey/SetupSurveyPage.vue'),
    },
    {
      name: 'setup-enrichment',
      path: 'enrichment',
      component: () =>
        import(
          '@/views/SurveyDashboard/SetupTab/Enrichment/EnrichmentPage.vue'
        ),
    },
  ],
};

export default SetupRoute;
