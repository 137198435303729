import ApiService from '@/api/ApiService';
import { i18n } from '@/plugins/i18n';
import '@/plugins/animator';
import { vuetify } from '@/plugins/vuetify';
import 'animate.css/animate.css';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import pinia from './stores';
import {
  Chart,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Title,
  BarElement,
  Tooltip,
  Legend,
} from 'chart.js';
import FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css';
import '@/plugins/clarity';
import { loadFonts } from '@/plugins/webfontloader';
import { loadSentry } from '@/plugins/sentry';
import VueGtag from 'vue-gtag';
import { isProduction } from './helpers/config';
import { loadIntercom } from './plugins/intercom';

Chart.register(
  LineController,
  LineElement,
  BarElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Title,
  Tooltip,
  Legend,
);

loadIntercom();

loadFonts();

const app = createApp(App);

loadSentry(app, router);

app
  .use(router)
  .use(vuetify)
  .use(pinia)
  .use(i18n)
  .use(FloatingVue)
  .use(
    VueGtag,
    {
      appName: 'OpinionX',
      pageTrackerScreenviewEnabled: true,
      enabled: isProduction,
      config: {
        id: 'G-85VXMLEPLW',
      },
    },
    router,
  )
  .mount('#app');

ApiService.init();
