import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import { aliases, mdi } from 'vuetify/iconsets/mdi';
import { md2 } from 'vuetify/blueprints';

const customLightTheme = {
  dark: false,
  colors: {
    primary: '#0F1F27',
    secondary: '#E6A821',
    background: '#F2F5F7',
    backgroundGray: '#FAFAFA',
    ctaBackground: '#FCF4E3',

    pendingText: '#9AABBC',
    pendingBackground: '#F3F5F7',
    sentText: '#F3F5F7',
    sentBackground: '#FFAD66',
    startedText: '#8F5959',
    startedBackground: '#F88F8C',
    completeText: '#648E7A',
    completeBackground: '#A9E8CD',
    defaultText: '#9AABBC',
  },
};

export const vuetify = createVuetify({
  blueprint: md2,
  theme: {
    defaultTheme: 'customLightTheme',
    themes: {
      customLightTheme,
    },
    variations: {
      colors: ['primary', 'secondary', 'surface'],
      lighten: 3,
      darken: 2,
    },
  },
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
});

type Order = 'asc' | 'desc';

export type SortBy = {
  key: string;
  order: Order;
};

export type SortString = `${string},${Order}`;
