<template>
  <v-snackbar
    v-model="messageStore.status"
    data-cy="message"
    :color="messageStore.type ?? 'info'"
    location="top"
    timeout="2000"
  >
    {{ messageStore.text }}
  </v-snackbar>
</template>

<script lang="ts">
import { mapStores } from 'pinia';
import { defineComponent } from 'vue';
import { useMessageStore } from '@/stores/message';

export default defineComponent({
  name: 'MessageNotification',
  computed: {
    ...mapStores(useMessageStore),
  },
});
</script>
