<template>
  <v-app>
    <loader />
    <message-box />
    <message-notification />
    <router-view />
  </v-app>
</template>

<script lang="ts">
import Loader from '@/components/loader/LoaderOverlay.vue';
import MessageNotification from '@/components/message/MessageNotification.vue';
import MessageBox from '@/components/message/MessageBox.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'App',
  components: {
    MessageBox,
    MessageNotification,
    Loader,
  },
});
</script>

<style>
thead,
.v-data-table .v-table__wrapper > table > thead > tr th {
  background-color: rgb(var(--v-theme-backgroundGray)) !important;
}

.v-card-title,
.v-card-subtitle,
.v-card-text,
.v-list-item-title,
.v-list-item-subtitle {
  word-break: break-word !important;
  white-space: initial !important;
  hyphens: none !important;
}

.v-select__selection-text,
.max-lines-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.max-lines-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.max-lines-4 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

.primary-border {
  border: 1px solid rgb(var(--v-theme-primary));
  font-size: 1rem;
  color: rgb(var(--v-theme-primary));
  font-weight: 500;
}

.primary-text {
  font-size: 1rem !important;
  color: rgb(var(--v-theme-primary)) !important;
  font-weight: 500 !important;
}

.ProseMirror.outline-none {
  outline: none;
}

.ProseMirror ul,
.ProseMirror ol {
  padding-inline-start: 1em;
}
</style>
