import { isProduction, isTest } from '@/helpers/config';
import { useUserStore } from '@/stores/user';
import {
  init,
  showReportDialog,
  replayIntegration,
  browserTracingIntegration,
} from '@sentry/vue';

const suppressedErrors = [
  'ResizeObserver loop completed with undelivered notifications',
];

function isSuppressedError(event: any) {
  return event.exception?.values?.some(
    (value: any) =>
      value.type === 'Error' &&
      suppressedErrors.some((suppressedError) =>
        value.value?.includes(suppressedError),
      ),
  );
}

function shouldShowReportDialog() {
  const userStore = useUserStore();
  const fiveMinutes = 1000 * 60 * 5;
  return (
    !isProduction ||
    !userStore.sentryErrorLastShown ||
    new Date().getTime() - new Date(userStore.sentryErrorLastShown).getTime() >
      fiveMinutes
  );
}

export function loadSentry(app: any, router: any) {
  if (isTest) {
    return;
  }
  init({
    app,
    dsn: 'https://63b313eb9e2d47e2845025bf8f685756@o1190876.ingest.sentry.io/6312087',
    integrations: [
      browserTracingIntegration({
        router: router,
      }),
      replayIntegration({
        maskAllText: false,
        blockAllMedia: true,
      }),
    ],
    beforeSend(event) {
      if (isSuppressedError(event)) {
        return event;
      }

      if (shouldShowReportDialog()) {
        const userStore = useUserStore();
        userStore.sentryErrorLastShown = new Date();
        showReportDialog({
          eventId: event.event_id,
          user: { email: userStore.email ?? undefined },
        });
      }

      return event;
    },
    tracePropagationTargets: [
      'localhost:4505',
      ...(import.meta.env.VITE_SERVER_HOST
        ? [import.meta.env.VITE_SERVER_HOST]
        : []),
    ],
    replaysSessionSampleRate: isProduction ? 0.3 : 0,
    replaysOnErrorSampleRate: isProduction ? 1 : 0,
    debug: false,
    release: VITE_APP_VERSION,
    environment: import.meta.env.MODE,
    tracesSampleRate: isProduction ? 0.3 : 0,
  });
}
