import { RouteRecordRaw } from 'vue-router';
import SurveyDashboardResultsRoute from './ResultsRoute';

const mapToPreviewResults = (route: RouteRecordRaw): RouteRecordRaw => {
  const children = route.children
    ? route.children.map(mapToPreviewResults)
    : route.children;
  const name = route.name ? `preview-${route.name?.toString()}` : undefined;
  const redirect =
    typeof route.redirect === 'object' && 'name' in route.redirect
      ? { name: `preview-${route.redirect.name?.toString()}` }
      : undefined;
  return {
    ...route,
    name,
    meta: { ...route.meta, shareableLink: true, authorize: false },
    children,
    redirect,
  } as RouteRecordRaw;
};

const PreviewResultsRoute = mapToPreviewResults(SurveyDashboardResultsRoute);

export default PreviewResultsRoute;
