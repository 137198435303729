export default [
  {
    path: 'intro',
    name: 'intro',
    component: () => import('@/views/Survey/IntroPage.vue'),
    meta: {
      name: 'Survey Intro',
      participant: true,
    },
  },
  {
    path: 'survey',
    name: 'survey',
    component: () => import('@/views/Survey/SurveyHome.vue'),
    meta: {
      name: 'Survey',
      participant: true,
      showFooter: true,
      scrollToTop: true,
    },
  },
  {
    path: 'finish',
    name: 'finish',
    component: () => import('@/views/Survey/FinishPage.vue'),
    meta: {
      name: 'Survey Finish',
      participant: true,
      scrollToTop: true,
    },
  },
  {
    path: 'closed',
    name: 'closed',
    component: () => import('@/views/Survey/Closed/ClosedPage.vue'),
    meta: {
      name: 'Survey Closed',
      participant: true,
    },
  },
  {
    path: 'link-used',
    name: 'link-used',
    component: () => import('@/views/Survey/Closed/LinkUsedPage.vue'),
    meta: {
      name: 'Survey Link Used',
      participant: true,
    },
  },
];
