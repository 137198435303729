import { useSubmissionStore } from '@/stores/submission';
import { useUserStore } from '@/stores/user';
import router from '@/router';
import axios, { AxiosRequestConfig } from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import Auth from './endpoints/Auth';

export type Params = Record<
  string,
  | Date
  | string
  | number
  | boolean
  | null
  | string[]
  | Record<string, any>
  | Record<string, any>[]
  | undefined
>;

export type RequestConfig = AxiosRequestConfig & { skipAuthRefresh?: boolean };

export default {
  init() {
    axios.defaults.baseURL = import.meta.env.VITE_SERVER_HOST + '/api';
    axios.defaults.withCredentials = true;
    axios.defaults.headers['Content-Type'] = 'application/json';

    const submissionStore = useSubmissionStore();

    axios.interceptors.request.use(
      (config) => {
        if (!config?.headers) {
          throw new Error(
            `Expected 'config' and 'config.headers' not to be undefined`,
          );
        }

        if (submissionStore.accessToken) {
          config.headers['Authorization'] =
            `Bearer ${submissionStore.accessToken}`;
        }

        if (router.currentRoute.value.meta?.shareableLink) {
          config.headers['shareable-link-id'] =
            router.currentRoute.value?.params?.id;
        }

        config.headers['Authorization'] =
          `Bearer ${submissionStore.accessToken}`;

        return config;
      },
      (error) => Promise.reject(error),
    );

    createAuthRefreshInterceptor(
      axios,
      async () =>
        await Auth.refreshToken().catch(() => {
          const userStore = useUserStore();
          userStore.signOut();
        }),
    );
  },
  get<T = any>(resource: string, config: RequestConfig = {}) {
    config.params = cleanConfigParams(config?.params);
    return axios.get<T>(resource, config);
  },
  post<T = any>(resource: string, payload = {}, config: RequestConfig = {}) {
    config.params = cleanConfigParams(config?.params);
    return axios.post<T>(resource, payload, config);
  },
  put(resource: string, payload = {}, config: RequestConfig = {}) {
    config.params = cleanConfigParams(config?.params);
    return axios.put(resource, payload, config);
  },
  patch<T = any>(resource: string, payload = {}, config: RequestConfig = {}) {
    config.params = cleanConfigParams(config?.params);
    return axios.patch<T>(resource, payload, config);
  },
  delete(resource: string) {
    return axios.delete(resource);
  },
};

const cleanConfigParams = (params: any) => {
  if (!params) {
    return {};
  }
  return Object.fromEntries(Object.entries(params).filter((v) => v[1] != null));
};
