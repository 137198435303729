import { defineStore } from 'pinia';
import { useUserStore } from './user';
import { notNull } from '@/helpers/notNull';
import Workspaces from '@/api/endpoints/Workspaces';
import { useMessageStore } from './message';
import { TierName, Tier, TierDisplayNames } from '@opinionx/tier';
import { useMessageBoxStore } from './messageBox';
import { isAxiosError } from 'axios';
import { useLoaderStore } from './loader';
import { Params } from '@/api/ApiService';

type Member = {
  _id: string;
  user?: string;
  email: string;
  access: 'owner' | 'admin' | 'teammate';
};

type domain = {
  validated: boolean;
  domain: string;
  validationEmail: string;
  _id: string;
};

type Subscription = {
  tier: Tier;
  extraEditorSeats: number;
  extraWorkspaces: number;
  customerId: string | null;
};

export type Workspace = {
  _id: string | null;
  name: string | null;
  members: Member[];
  shareableLinkId: string | null;
  domains: domain[];
  subscription: Subscription;
  createdAt: Date | null;
  updatedAt: Date | null;
};

export type WorkspaceState = {
  workspaces: Workspace[];
  currentWorkspaceIndex: number;
  loading: boolean;
};

export const useWorkspaceStore = defineStore('workspace', {
  state: (): WorkspaceState => ({
    workspaces: [],
    currentWorkspaceIndex: 0,
    loading: false,
  }),
  getters: {
    isWriteAccess(): boolean {
      const userStore = useUserStore();
      if (userStore?.isAdmin) {
        return true;
      }
      const userMember = this.members.find(
        (member) => userStore?._id === member?.user,
      );
      if (!userMember) {
        return false;
      }
      return ['owner', 'admin'].includes(userMember.access);
    },
    currentWorkspace(state): Workspace | null {
      return state.workspaces[this.currentWorkspaceIndex] ?? null;
    },
    members(): Member[] {
      return this.currentWorkspace?.members ?? [];
    },
    _id(): string | null {
      return this.currentWorkspace?._id ?? null;
    },
    isOwner(): boolean {
      const userStore = useUserStore();
      if (userStore.isAdmin) {
        return true;
      }
      const userMember = this.members.find(
        (member) => userStore?._id === member?.user,
      );
      if (!userMember) {
        return false;
      }
      return userMember.access === 'owner';
    },
    userOwnedWorkspaces(): Workspace[] {
      const userStore = useUserStore();

      return this.workspaces.filter(({ members }) =>
        members.some(
          ({ access, user }) => access === 'owner' && user === userStore._id,
        ),
      );
    },
    workspaceShareableLink(): string | null {
      return this.currentWorkspace?.shareableLinkId
        ? `${window.location.origin}/workspace-invite/${this.currentWorkspace.shareableLinkId}`
        : null;
    },
  },
  actions: {
    async find() {
      const userStore = useUserStore();
      const messageStore = useMessageStore();
      this.loading = true;
      try {
        notNull(userStore._id, 'userStore._id');
        const { data } = await Workspaces.findAll({
          'members.user': userStore._id,
        });
        if (data === 0) {
          await this.goToWorkspaceSetup();
        }
        this.workspaces = data;
        if (this.currentWorkspaceIndex >= this.workspaces.length) {
          this.currentWorkspaceIndex = 0;
        }
      } catch {
        messageStore.message('error', 'Error fetching workspaces');
      } finally {
        this.loading = false;
      }
    },
    async create() {
      const userStore = useUserStore();

      if (this.workspaces.length === 0) {
        useLoaderStore().loaderOn();
        try {
          const name = userStore.profile?.name.first
            ? `${userStore.profile?.name.first}'s Workspace`
            : 'My Workspace';
          const { data } = await Workspaces.create({ name }, {});
          this.workspaces.unshift(data);
          this.currentWorkspaceIndex = 0;
          this.goToCurrentWorkspaceSetup();
        } catch (error) {
          useMessageStore().message('error', 'Error creating Workspace');
          throw error;
        } finally {
          useLoaderStore().loaderOff();
        }
      } else if (
        this.currentWorkspace?.subscription?.tier.name === TierName.FREE
      ) {
        useMessageBoxStore().messageBox({
          title: `Additional workspace are available at ${TierDisplayNames.pro} tier 💲`,
          text: 'Having multiple workspaces gives you better control over who is part of your team and what surveys those teammates have access to.',
          actionText: 'Upgrade',
          action: () => this.router.push({ name: 'upgrade' }),
          cancelText: 'Cancel',
        });
      } else {
        return this.createPremiumWorkspace();
      }
    },
    createPremiumWorkspace() {
      const userStore = useUserStore();

      const action = async () => {
        const loaderStore = useLoaderStore();
        try {
          loaderStore.loaderOn();
          notNull(this.currentWorkspace?._id, 'workspace._id');
          const name = userStore.profile?.name.first
            ? `${userStore.profile?.name.first}'s Workspace`
            : 'My Workspace';
          const { data } = await Workspaces.create(
            { name },
            { subscriptionWorkspaceId: this.currentWorkspace._id },
          );
          this.workspaces.unshift(data);
          this.currentWorkspaceIndex = 0;
          this.goToCurrentWorkspaceSetup();
        } catch {
          useMessageStore().message('error', 'Error creating workspace');
        } finally {
          loaderStore.loaderOff();
        }
      };

      useMessageBoxStore().messageBox({
        title: 'Create A New Workspace 💲',
        text: 'Having multiple workspaces gives you better control over who is part of your team and what surveys those teammates have access to. If you choose to proceed, your subscription will be updated automatically to include your new workspace ($20/month).',
        actionText: 'Continue',
        action,
        cancelText: 'Back',
      });
    },
    goToCurrentWorkspaceSetup() {
      this.router.push({
        name: 'workspace-setup',
        params: { workspaceId: this.currentWorkspace?._id },
      });
    },
    async update(
      payload: Partial<Omit<Workspace, '_id' | 'members'>>,
    ): Promise<void> {
      const messageStore = useMessageStore();
      try {
        notNull(this.currentWorkspace?._id, '_id');
        const { data } = await Workspaces.update(
          this.currentWorkspace?._id,
          payload,
        );
        messageStore.message('success', 'Workspace updated.');
        this.workspaces.splice(this.currentWorkspaceIndex, 1, data);
      } catch {
        messageStore.message('error', 'Error updating workspace');
      }
    },
    goToWorkspaceSetup() {
      return this.router.replace({ name: 'welcome', query: { tab: 2 } });
    },
    async delete() {
      const messageStore = useMessageStore();
      try {
        notNull(this.currentWorkspace?._id, '_id');
        await Workspaces.remove(this.currentWorkspace?._id);
        messageStore.message('success', 'Workspace deleted.');
        if (this.workspaces.length === 1) {
          await this.goToWorkspaceSetup();
        }
        this.workspaces.splice(this.currentWorkspaceIndex, 1);
        this.currentWorkspaceIndex = 0;
      } catch {
        messageStore.message('error', 'Error deleting workspace');
      }
    },
    async addMember(payload: Record<string, any>) {
      const messageStore = useMessageStore();
      try {
        notNull(this.currentWorkspace?._id, '_id');
        const { data } = await Workspaces.addMember(
          this.currentWorkspace._id,
          payload,
        );
        messageStore.message('success', 'Member invited.');
        this.workspaces.splice(this.currentWorkspaceIndex, 1, data);
      } catch {
        messageStore.message('error', 'Error inviting member');
      }
    },
    async updateMember(
      memberId: string,
      payload: { access: 'teammate' | 'admin' },
    ) {
      const messageStore = useMessageStore();
      try {
        notNull(this.currentWorkspace?._id, '_id');
        const { data } = await Workspaces.updateMember(
          this.currentWorkspace?._id,
          memberId,
          payload,
        );
        this.workspaces.splice(this.currentWorkspaceIndex, 1, data);
        messageStore.message('success', 'Member updated successfully');
      } catch {
        messageStore.message('error', 'Error updating member');
      }
    },
    async removeMember(memberId: string) {
      const messageStore = useMessageStore();
      try {
        notNull(this.currentWorkspace?._id, '_id');
        const { data } = await Workspaces.removeMember(
          this.currentWorkspace?._id,
          memberId,
        );
        this.workspaces.splice(this.currentWorkspaceIndex, 1, data);
        messageStore.message('success', 'Member removed from workspace');
      } catch {
        messageStore.message(
          'error',
          'Error removing member from this workspace',
        );
      }
    },
    async setCurrentWorkspaceById(workspaceId: string) {
      let index = this.workspaces.findIndex(({ _id }) => _id === workspaceId);
      if (index < 0) {
        await this.find();
        index = this.workspaces.findIndex(({ _id }) => _id === workspaceId);
      }
      if (index >= 0) {
        this.currentWorkspaceIndex = index;
      }
    },
    async setCurrentWorkspaceBySharableLinkId(
      workspaceShareableLinkId: string,
    ) {
      let index = this.workspaces.findIndex(
        ({ shareableLinkId }) => shareableLinkId === workspaceShareableLinkId,
      );
      if (index < 0) {
        await this.find();
        index = this.workspaces.findIndex(
          ({ shareableLinkId }) => shareableLinkId === workspaceShareableLinkId,
        );
      }
      if (index >= 0) {
        this.currentWorkspaceIndex = index;
      }
    },
    async findAndSetUsersInitialWorkspace() {
      const userStore = useUserStore();
      await this.find();
      const index = this.workspaces.findIndex((workspace) =>
        workspace.members.some(
          (member) =>
            member.access === 'owner' && member.email === userStore.email,
        ),
      );
      if (index >= 0) {
        this.currentWorkspaceIndex = index;
      }
    },
    async addMemberByShareableLink(shareableLinkId: string) {
      try {
        const { data } =
          await Workspaces.addMemberByShareableLink(shareableLinkId);
        await this.setCurrentWorkspaceById(data._id);
      } finally {
        this.router.replace({ name: 'surveys' });
      }
    },
    async addDomain(payload: Record<string, any>, params?: Params) {
      const messageStore = useMessageStore();
      const messageBoxStore = useMessageBoxStore();
      try {
        notNull(this.currentWorkspace?._id, '_id');
        const { data } = await Workspaces.addDomain(
          this.currentWorkspace._id,
          payload,
          params,
        );
        this.workspaces.splice(this.currentWorkspaceIndex, 1, data);
        const newDomain = this.currentWorkspace.domains.slice(-1)[0];
        if (newDomain?.validated) {
          messageBoxStore.messageBox({
            title: 'Domain permission enabled!',
            text: `Anyone from your team with an email address from the domain ${newDomain.domain} can now automatically join your workspace when they sign up to OpinionX. To edit this permission, visit your workspace settings.`,
            actionText: 'Ok',
          });
        } else {
          messageBoxStore.messageBox({
            title: 'Email Verification Required',
            text: `To allow teammates with ${newDomain?.domain} email addresses to join your workspace, please complete the verification process we just sent you via email.`,
            actionText: 'Proceed',
            actionColor: 'success',
          });
        }
      } catch (error) {
        if (isAxiosError(error) && error.response?.status === 400) {
          messageStore.message(
            'error',
            `Error inviting member: ${error.response?.data?.message}`,
          );
        } else {
          messageStore.message('error', 'Error adding Domain');
        }
      }
    },
    async removeDomain(domainId: string) {
      const messageStore = useMessageStore();
      try {
        notNull(this.currentWorkspace?._id, '_id');
        const { data } = await Workspaces.removeDomain(
          this.currentWorkspace?._id,
          domainId,
        );
        this.workspaces.splice(this.currentWorkspaceIndex, 1, data);
        messageStore.message('success', 'Domain removed from workspace');
      } catch {
        messageStore.message(
          'error',
          'Error removing domain from this workspace',
        );
      }
    },
    async sendDomainValidation(domainId: string) {
      if (!this.isWriteAccess) {
        return;
      }
      const messageStore = useMessageStore();
      try {
        notNull(this.currentWorkspace?._id, '_id');
        await Workspaces.sendDomainValidation(
          this.currentWorkspace?._id,
          domainId,
        );
        messageStore.message('success', 'Domain validation sent');
      } catch {
        messageStore.message('error', 'Error sending domain validation');
      }
    },
  },
  persist: {
    enabled: true,
  },
});
