import ApiService, { Params } from '@/api/ApiService';

export default {
  create(createUserDto: any, params?: Params) {
    const config = {
      params,
    };
    return ApiService.post(`/users`, createUserDto, config);
  },
  findAll(params: Params) {
    const config = {
      params,
    };
    return ApiService.get(`/users`, config);
  },
  overview(params: Params) {
    const config = {
      params,
    };
    return ApiService.get(`/users/overview`, config);
  },
  findOne(id: string) {
    return ApiService.get(`/users/${id}`);
  },
  update(id: string, updateUserDto: any) {
    return ApiService.patch(`/users/${id}`, updateUserDto);
  },
  upgrade(id: string, params: Params) {
    const config = {
      params,
    };
    return ApiService.patch(`/users/${id}/upgrade`, {}, config);
  },
  remove(id: string) {
    return ApiService.delete(`/users/${id}`);
  },
};
