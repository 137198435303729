import Auth from '@/api/endpoints/Auth';
import Users from '@/api/endpoints/Users';
import { notNull } from '@/helpers/notNull';
import axios from 'axios';
import { defineStore } from 'pinia';
import { useMessageStore } from './message';
import * as Sentry from '@sentry/vue';
import { useMessageBoxStore } from './messageBox';
import { useWorkspaceStore } from './workspace';
import { RouteLocationRaw } from 'vue-router';
import { Params } from '@/api/ApiService';
import { captureMessage } from '@sentry/vue';

const inviteTokenHasExpiredMessage = (
  messageStore: ReturnType<typeof useMessageStore>,
) => {
  messageStore.message(
    'error',
    'This invitation token has expired, please request a new invitation token.',
  );
};

export type UserState = {
  _id?: string;
  email?: string;
  roles?: any[];
  settings?: any;
  isAuthenticated: boolean;
  extraEditorSeats: number;
  extraWorkspaces: number;
  profile: {
    name: { first?: string; last?: string };
  } | null;
  verified?: boolean;
  createdAt?: string;
  sentryErrorLastShown?: Date;
};

export const useUserStore = defineStore('user', {
  state: (): UserState => ({
    _id: undefined,
    email: undefined,
    roles: [],
    settings: undefined,
    isAuthenticated: false,
    profile: null,
    extraEditorSeats: 0,
    extraWorkspaces: 0,
    verified: undefined,
    sentryErrorLastShown: undefined,
  }),
  getters: {
    isAdmin: (state) => state.roles?.includes('admin'),
    fullName(): string | undefined {
      let fullName = '';
      if (this.profile?.name.first) {
        fullName = this.profile.name.first;
      }
      if (this.profile?.name.last) {
        fullName += (fullName ? ' ' : '') + this.profile.name.last;
      }
      return fullName ?? undefined;
    },
  },
  actions: {
    async googleAuth(payload: any, params?: Params) {
      try {
        useWorkspaceStore().$reset();
        const { data, status } = await Auth.googleAuth(payload, params);
        this.isAuthenticated = true;
        await this.setState(data);
        if (status === 201) {
          await this.navigateToNextRoute({
            name: 'welcome',
            query: { tab: 0 },
          });
        } else {
          await this.navigateToNextRoute({
            name: 'surveys',
          });
        }
      } catch (error) {
        const messageStore = useMessageStore();
        if (axios.isAxiosError(error)) {
          switch (error.response?.status) {
            case 412:
              inviteTokenHasExpiredMessage(messageStore);
              return;
            default:
              throw error;
          }
        }
        throw error;
      }
    },
    async signUp(payload: any, params?: Params) {
      try {
        useWorkspaceStore().$reset();
        const { data } = await Auth.signUp(payload, params);
        this.isAuthenticated = true;
        await this.setState(data);
        await this.navigateToNextRoute({
          name: 'welcome',
          query: { tab: 0 },
        });
      } catch (error) {
        const messageStore = useMessageStore();
        if (axios.isAxiosError(error)) {
          switch (error.response?.status) {
            case 409:
              messageStore.message(
                'error',
                'An account with this email already exists, please sign in',
              );
              return;
            case 412:
              inviteTokenHasExpiredMessage(messageStore);
              return;
            default:
              throw error;
          }
        }
        throw error;
      }
    },
    async signIn(payload: any, params?: Params) {
      try {
        useWorkspaceStore().$reset();
        const { data } = await Auth.signIn(payload, params);
        this.isAuthenticated = true;
        await this.setState(data);
        const defaultRoute = data.roles.includes('admin')
          ? { name: 'admin-overview' }
          : { name: 'surveys' };
        await this.navigateToNextRoute(defaultRoute);
      } catch (error) {
        const messageStore = useMessageStore();
        if (axios.isAxiosError(error)) {
          switch (error.response?.status) {
            case 401:
              messageStore.message(
                'error',
                'Please check your email & password.',
              );
              return;
            case 404:
              messageStore.message(
                'error',
                'No account exists with this email.',
              );
              return;
            case 412:
              inviteTokenHasExpiredMessage(messageStore);
              return;
            default:
              throw error;
          }
        }
        throw error;
      }
    },
    async findOne() {
      notNull(this._id, '_id');
      const { data } = await Users.findOne(this._id);
      this.$state = data;
    },
    async update(payload: any) {
      notNull(this._id, '_id');
      const { data } = await Users.update(this._id, payload);
      this.$state = data;
    },
    async signOut() {
      window.Intercom?.('shutdown');
      this.$reset();
      await this.router.replace({ name: 'sign-in' }).catch(() => {});
      useWorkspaceStore().$reset();
    },
    signOutWithoutNavigate() {
      window.Intercom?.('shutdown');
      this.$reset();
      useWorkspaceStore().$reset();
    },
    deleteAccount() {
      const messageBoxStore = useMessageBoxStore();

      const action = async () => {
        notNull(this._id, 'userStore._id');
        await Users.remove(this._id);
        await this.signOut();
      };
      messageBoxStore.messageBox({
        title: 'Delete Account',
        text: 'This will permanently delete your account, surveys and any active subscriptions.',
        action,
        actionText: 'Delete',
        actionColor: 'error',
        cancelText: 'Cancel',
      });
    },
    async setState(data: any) {
      this.$state = data;
      Sentry.setUser({ id: this._id, email: this.email });

      window.Intercom?.('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'u7i7f1yn',
        name: this.fullName,
        email: this.email,
        created_at: this.createdAt,
      });

      try {
        if (window?.clarity) {
          await window?.clarity('identify', this._id);
        }
      } catch (error) {
        captureMessage('could not set clarity id for identify', {
          extra: { error },
        });
      }
    },
    async sendVerificationEmail() {
      const messageStore = useMessageStore();
      try {
        notNull(this._id, '_id');
        await Auth.sendVerificationEmail();
        messageStore.message('success', 'Verification email sent');
      } catch {
        messageStore.message('success', 'Error sending verification email.');
      }
    },
    async verifyEmail(token: string) {
      const messageStore = useMessageStore();
      try {
        notNull(this._id, '_id');
        const { data } = await Auth.verifyEmail({ token });
        await this.setState(data);
        messageStore.message('success', 'Email Verified');
        this.router.replace('/');
      } catch {
        messageStore.message('error', 'Error verifying email');
        this.router.replace({ name: 'not-found' });
      }
    },
    navigateToNextRoute(defaultRoute: RouteLocationRaw) {
      const currentRoute = this.router.currentRoute.value;
      if (currentRoute.params.workspaceShareableLinkId) {
        return this.router
          .replace({
            name: 'surveys',
            query: {
              workspaceShareableLinkId:
                currentRoute.params.workspaceShareableLinkId.toString(),
            },
          })
          .catch(() => {});
      } else if (currentRoute.query.verifyEmailToken) {
        return this.router
          .replace({
            name: 'verify-email',
            params: { userId: this._id },
            query: {
              token: currentRoute.query.verifyEmailToken.toString(),
            },
          })
          .catch(() => {});
      } else if (currentRoute.query.surveyShareableLinkId) {
        return this.router
          .replace({
            name: 'preview results',
            params: {
              id: currentRoute.query.surveyShareableLinkId.toString(),
            },
          })
          .catch(() => {});
      } else {
        return this.router.replace(defaultRoute).catch(() => {});
      }
    },
  },
  persist: {
    enabled: true,
    strategies: [{ storage: localStorage }],
  },
});
