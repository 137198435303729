import { isProduction } from '@/helpers/config';

function loadClarity() {
  if (!isProduction) {
    return;
  }

  const script = document.createElement('script');
  script.async = true;
  script.defer = true;
  script.innerText =
    '(function(c,l,a,r,i,t,y){c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) }; t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i; y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y); }) (window, document, "clarity", "script", "k1rapk1d6q"); ';
  document.head.appendChild(script);
}

declare global {
  interface Window {
    clarity?: (...args: any[]) => Promise<void>;
  }
}

loadClarity();
