import { createPinia } from 'pinia';
import piniaPersist from 'pinia-plugin-persist';
import { markRaw } from 'vue';
import { Router } from 'vue-router';
import router from '@/router';

const pinia = createPinia();
pinia.use(piniaPersist);

pinia.use(({ store }) => {
  store.router = markRaw(router);
});

export default pinia;

declare module 'pinia' {
  export interface PiniaCustomProperties {
    router: Router;
  }
}
